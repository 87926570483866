<template>
  <v-card
    v-if="createBottomNavigation"
    :style="{ zIndex: $zIndexConfig.formAction }"
    class="my-4 px-0"
    elevation="0"
  >
    <v-row>
      <formActionStatus
        v-if="hasWritePermission"
        :form-key="formKey"
      ></formActionStatus>
      <formActionBack :form-key="formKey"></formActionBack>
      <formActionSave
        v-if="hasWritePermission"
        :form-key="formKey"
      ></formActionSave>
      <formActionDelete
        v-if="hasWritePermission"
        :form-key="formKey"
      ></formActionDelete>
      <formActionRefresh :form-key="formKey"></formActionRefresh>
    </v-row>
    <!-- <formActionStatus v-if="hasWritePermission" :form-key="formKey"></formActionStatus>
      <formActionSave v-if="hasWritePermission" :form-key="formKey"></formActionSave>
      <formActionRefresh :form-key="formKey"></formActionRefresh>
      <formActionBack :form-key="formKey"></formActionBack>
      <formActionDelete v-if="hasWritePermission" :form-key="formKey"></formActionDelete> -->
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`form/${this.formKey}/hasWritePermission`]
    },
    createBottomNavigation() {
      if(!this.formConfig) return true
      if(this.formConfig.disabledBottomNavigation == undefined) return true
      return this.formConfig.disabledBottomNavigation != true
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
  },
  components: {
    formActionStatus: () => import('@/components/form/formAction/status.vue'),
    formActionSave: () => import('@/components/form/formAction/save.vue'),
    formActionRefresh: () => import('@/components/form/formAction/refresh.vue'),
    formActionBack: () => import('@/components/form/formAction/back.vue'),
    formActionDelete: () => import('@/components/form/formAction/delete.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
